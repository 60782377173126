import React from "react";
import "./style.scss";

function App() {
  return (
    <div className="container">
      <video
        src="/bg.mp4"
        autoPlay
        loop
        playsInline
        muted
        className="video-bg"
      ></video>
      <div className="content">
        <div className="logo" />
        <div className="bold-text">
          <h1>A Hybrid Venture Studio</h1>
          <h1>& a Pre-seed Fund.</h1>
        </div>
        <p className="regular-text">
          We partner with visionary co-founders and companies to create new
          markets or redefine existing ones, building companies that transform
          how we live, at a very early stage.
        </p>
        <p className="regular-text">
          We take a rapid methodology-driven approach to de-risk the process of
          building and scaling companies.
        </p>
        <p className="regular-text">
          Our team is a mix of serial entrepreneurs, investors, operators, and
          ecosystem builders, who have built and exited venture-backed startups
          using our proprietary process, network, resources, extensive support
          and capital, founders can only focus on execution.
        </p>

        <div className="flex-row">
          <div className="founders">
            <p className="title">Founders</p>
            <p className="sub-title">Co-found with us</p>
            <p className="text">
              Get exclusive access to our proprietary process, network,
              resources, building proces and capital.
            </p>
            <div
              className="btn"
              onClick={() => {
                window.location.href = "mailto:founders@venturemkr.com";
              }}
            >
              Get in touch
            </div>
          </div>

          <div className="companies">
            <p className="title">Companies</p>
            <p className="sub-title">Co-found with us</p>
            <p className="text">
              Eliminate the guesswork using our systematic framework, and
              approach to build and scale with confidence.
            </p>
            <div
              className="btn"
              onClick={() => {
                window.location.href = "mailto:companies@venturemkr.com";
              }}
            >
              Get in touch
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
